.selectContainer {
  z-index: 500;
  max-height: 300px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 1px 2px 0px #0000001f;
  overflow: auto;
}

.selectItem:hover {
  background-color: #59638b26;
}
.select-direction-bottom {
  right: -5px;
  top: 25px;
}

.select-direction-top {
  right: -5px;
  bottom: 50px;
}
