@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@import 'react-big-calendar/lib/css/react-big-calendar.css';


/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.nursery-bg {
  background-image: url(../src/assets/img/images/bgg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius:100px;
  position: relative;
  z-index: 1;

  align-items: center;
}
.nursery-sidbar-bg {
  background-image: url(../src/assets/img/images/bgg.jpg);
  background-size: cover;
  background-repeat: repeat;
  background-position: left;
  /* border-radius: 100px;
  position: relative;
  z-index: 1;

  align-items: center; */
}
